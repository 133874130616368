import React, { useEffect } from "react";

const Itvas = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <h1 className=" text-center my-5">IT & VAS</h1>
      <div className="row text-center">
        <div className="col-lg-8">
          <img
            className="mw-100 h-100 w-auto mx-auto"
            src={require("./media/itvasInteca.jpeg")}
            alt="IT VAS INTECA"
          />
        </div>
        <div className="col-lg-4 py-5">
          <h4 className="text-start px-4">
            · Integrador de soluciones móviles para SaaS
          </h4>
          <p className="text-start px-4">
            Contamos con el soporte de dos fábricas de software líderes en
            Inteligencia Artificial, Chatbots y BIG Data.
          </p>
          <br />
          <h4 className="text-start px-4">
            · Soluciones integrales de seguridad para prevención de fraude y
            protección de identidad
          </h4>
          <p className="text-start px-4">
            Ayudamos a mantener tus datos corporativos seguros ante la creciente
            cantidad de riesgos y ciberdelitos.
          </p>
          <br />
          <h4 className="text-start px-4">
            · Servicios de Business Process Outsourcing, Information Technology
            Outsourcing & Knowledge Process Outsourcing
          </h4>
          <p className="text-start px-4">
            Consideramos que en el crecimiento de las empresas la tercerización
            de actividades de misión crítica permiten un mejor enfoque en las
            actividades propias del giro de negocio de cada compañía.
          </p>
        </div>
      </div>
    </>
  );
};

export default Itvas;
