import { React, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { useForm } from "../../shared/hooks/form-hook";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
} from "../util/validators";
import { useHttpClient } from "../../shared/hooks/http-hook";
import emailService from '../../services/email';
import './ContactForm.css'
const ContactForm = () => {

  const [captachValido, cambiarCaptchaValido] = useState(null);
  const [usuarioValido, cambiarUsuarioValido] = useState(false);
  const [envio, setEnvio] = useState(false);


  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      message: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const onChange = () => {
    if (captcha.current.getValue()) {
      //console.log("El usuario no es un robot");
      cambiarCaptchaValido(true);
      cambiarUsuarioValido(true)
    };
  }

  const captcha = useRef(null);


  const placeSubmitHandler = async (event) => {
    event.preventDefault();

    const newEmail = {
      name: formState.inputs.name.value,
      email: formState.inputs.email.value,
      message: formState.inputs.message.value
    }




    if (captcha.current.getValue()) {
      //console.log("El usuario no es un robot");
      cambiarUsuarioValido(true);
      cambiarCaptchaValido(true);
      setEnvio(true);

      if (formState.inputs.name.isValid &&
        formState.inputs.email.isValid &&
        formState.inputs.message.isValid) {


        emailService.send_mail(newEmail)
       // console.log("se envipo")
      }




    } else {
      //console.log("Por favor acepta el captcha");
      cambiarUsuarioValido(false);
      cambiarCaptchaValido(false);
    }

  };

  return (
    <>
      <form
        className="place-form p-4 text-start"
        onSubmit={placeSubmitHandler}
        style={{ backgroundColor: "#53110A" }}
      >
        <div className="h4 text-start">
          <h1>CONTÁCTANOS</h1>
        </div>

        <Input
          id="name"
          element="input"
          type="text"
          label="Nombre"
          validators={[VALIDATOR_REQUIRE]}
          errorText="Please enter a valid name."
          onInput={inputHandler}
        />
        <Input
          id="email"
          element="input"
          type="text"
          label="Email"
          validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRE]}
          errorText="Please enter a valid email address."
          onInput={inputHandler}
        />
        <Input
          id="message"
          element="textarea"
          type="text"
          label="Mensaje"
          validators={[VALIDATOR_MINLENGTH(10), VALIDATOR_REQUIRE]}
          errorText="Please enter a valid message (at least 10 characters)."
          onInput={inputHandler}
        />

        <br />
        <div className="container">
          <div className="row row justify-content-center">
            <div className="col-sm">
              <ReCAPTCHA
                ref={captcha}
                sitekey='6Lc8F98hAAAAABN4rKJOkE3Kn9EZEHvaPJqQyoOc'
                onChange={onChange}
              />
            </div>


            <div className="row row justify-content-center mt-4">
            <div className="col-sm pe-3">
              <Button type="submit" disabled={!formState.isValid && !usuarioValido}>
              ENVIAR MENSAJE
              </Button>
              {captachValido === false && <div className='error-captcha'>Por favor acepta el captcha</div>}
              {envio === true && <div className='error-captcha'> Se envió el correo electrónico</div>}

              </div>
            </div>
          </div>



        </div>

      </form>
    </>
  );
};

export default ContactForm;
//<ReCAPTCHA sitekey="6LefGeAfAAAAAD8BXk5cfNBv_dLb7ZiJBe4JqRUh" />
