import React, { useEffect } from "react";

const Financiero = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <h1 className=" text-center my-5">SECTOR FINANCIERO</h1>
      <div className="row text-center">
        <div className="col-lg-8">
          <img
            className="mw-100 h-100 w-auto mx-auto"
            src={require("./media/financieroInteca.jpeg")}
            alt="buildings"
          />
        </div>
        <div className="col-lg-4 py-5">
          <h4 className="text-start px-4">
            · Asesoría en plataformas digitales
          </h4>
          <p className="text-start px-4">
            Brindamos un completo abanico de soluciones basados en nuestra
            experiencia regional y nuestras alianzas estratégicas.
          </p>
          <br />
          <h4 className="text-start px-4">
            · Consultorías en riesgos operacionales y tecnológicos y
            cumplimiento regulatorio
          </h4>
          <p className="text-start px-4">
            Ofrecemos un Know How en aspectos regulatorios que permiten a
            nuestros clientes adoptar soluciones digitales.
          </p>
          <br />
          <h4 className="text-start px-4">
            · Asesoría en Soluciones de Pago Digitales
          </h4>
          <p className="text-start px-4">
            Soluciones enfocadas en que las instituciones financieras puedan
            brindar una sofisticada orquestación de los servicios financieros en
            el ecosistema de medios de pago para soluciones de Financial Supply
            Chain.
          </p>
        </div>
      </div>
    </>
  );
};

export default Financiero;
