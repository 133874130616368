import React from "react";

const Us = () => {
  return (
    <>
    <h1 className="text-center m-5">NOSOTROS</h1>
      <div
        className="bg-image-cropped"
        style={{
          backgroundImage: 'url(".//media/us.jpg")',
          height: "350px",
        }}
      >
      </div>
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6 p-5 text-center">
          <p className="text-black">
            Nuestro equipo de Gerencia está comprometido con las mejores
            prácticas empresariales, con la innovación disruptiva y la
            creatividad permanente.
          </p>
          <br />
          <p className="text-black">
            Las sesiones de trabajo se realizan siguiendo metodologías como el
            Design Thinking y mediante plataformas colaborativas que nos
            permiten crear un ecosistema funcional con soluciones prácticas que
            permitan a nuestros clientes medir el retorno sobre su inversión --
            ROI -- y que se enmarquen en los parámetros de la modernidad en
            cuanto a la digitalización que se está produciendo en todas las
            verticales de las industrias debido al internet de las cosas
            --IoT--.
          </p>
          <br />
          <p className="text-black">
            Estamos comprometidos con el medio ambiente y con la calidad de
            nuestros productos y servicios, es nuestra manera de enfocarnos en
            nuestros stakeholders.
          </p>
        </div>
        <div className="col-lg-3"></div>
      </div>
    </>
  );
};

export default Us;
