import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Consultoria from "./Consultoria";
import Financiero from "./Financiero";

import Foot from "./Foot";
import Home from "./Home";
import Itvas from "./Itvas";

import Header from "./Header";
import Us from "./Us";

const App = () => {
  document.getElementById("root");
  return (
    <BrowserRouter>
      <main>
        <Header/>
        <Routes>
          <Route index element={<Home />} />
          <Route path="us" element={<Us />} />
          <Route path="servicios/financiero" element={<Financiero />} />
          <Route path="servicios/itvas" element={<Itvas />} />
          <Route path="servicios/consultoria" element={<Consultoria />} />
          <Route
            path="*"
            element={<div className="h2 text-danger d-flex align-items-center justify-content-center" style={{height:"350px"}}>PAGE NOT FOUND</div>}
          />
        </Routes>
        <Foot />
      </main>
    </BrowserRouter>
  );
};

export default App;
