import React from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";

const Header = (props) => {
  return (
    <>
      <div className="container-fluid px-0 py-3 px-sm-5">
        <div className="row align-items-center">
          <div className="col text-center m-2">
            <NavLink to="/">
              <img
                className="headerStyle"
                src={require("./media/LogoInteca.jpg")}
                alt="INTECA Logo"
              />
            </NavLink>
          </div>
          <div className="col align-self-center">
            <div className="d-flex py-3 justify-content-center">
              <a
                href="https://twitter.com/techconsult_la"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className="w-75"
                  src={require("./media/TwitterLogo.webp")}
                  alt="Twitter INTECA"
                />
              </a>
            </div>
            <nav className="col navbar navbar-expand-sm navbar-light">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mynavbar"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="mynavbar">
                <ul className="navbar-nav mx-auto">
                  <li className=" text-center nav-item px-sm-3">
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li className=" text-center nav-item px-sm-3">
                    <NavLink to="/#AreasDeNegocio">Áreas de Negocio</NavLink>
                  </li>
                  <li className=" text-center nav-item px-sm-3">
                    <NavLink to="/us">Us</NavLink>
                  </li>
                  <li className=" text-center nav-item px-sm-3">
                    <NavLink to="/#contact">Contact</NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
