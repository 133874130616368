import React from "react";
import Carousel from "react-bootstrap/Carousel";

import videoSrc from "./media/Waterdrop.mp4";
import "./Home.css";

const Home = () => {
  return (
    <>
      <div className="video-container">
        <video src={videoSrc} autoPlay={true} muted={true} loop={true} />
        <div className="centered">
          <h1 className="py-5" style={{ color: "#137AD5", fontSize: "3rem" }}>
            Más de 30 años de experiencia
          </h1>
          <p className="px-3">
            Nuestra visión de la tecnología nos ha posicionado como una compañía
            líder en innovación a nivel regional. Esto posibilitó a través de
            los años, que hoy contemos con una importante y valiosa cartera de
            clientes que depositan diariamente su confianza en nosotros.
          </p>
          <h3 className="px-3 text-end">#innovaciondigital</h3>
        </div>
      </div>
      <div className="text-center my-5">
        <h2 className="m-4 pt-3">NUESTRO OBJETIVO PRIMORDIAL</h2>
        <p className="m-auto pb-4 pt-2 w-75">
          es brindar a nuestros clientes un asesoramiento global en sus
          necesidades corporativas de tecnología y conectividad, para lo cual
          contamos con personal altamente capacitado y con experiencia que
          aseguran una eficaz relación comercial, siendo nuestra meta el
          convertirnos en un socio estratégico de nuestros clientes.
        </p>
      </div>
      <div className="">
        <Carousel controls={false}>
          <Carousel.Item>
            <img
              className="w-100 height-special-img"
              src={require("./media/buildings.jpeg")}
              alt="buildings"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="w-100 height-special-img"
              src={require("./media/meeting.jpeg")}
              alt="meetings"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="w-100 height-special-img"
              src={require("./media/advicing.jpeg")}
              alt="Advicing"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default Home;
