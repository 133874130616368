import React, { useEffect } from "react";

const Consultoria = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <h1 className=" text-center my-5">CONSULTORÍA</h1>
      <div className="row text-center">
        <div className="col-lg-8">
          <img
            className="mw-100 h-100 w-auto mx-auto"
            src={require("./media/consultoriaInteca.jpeg")}
            alt="buildings"
          />
        </div>
        <div className="col-lg-4 py-5">
          <p className="text-start px-4">
            Nuestro equipo ejecutivo tiene más de 100 años de experiencia
            acumulada en las industrias: Financiera, Banca, Telecomunicaciones,
            Seguridad de la información y Software.
          </p>
          <br />
          <p className="text-start px-4">
            Somos un catalizador para que nuestros clientes puedan desarrollar
            nuevos "revenue streams" basados en negocios disruptivos que parten
            de la innovación digital. Contamos con el recurso humano adecuado
            para apoyar a su negocio.
          </p>
        </div>
      </div>
    </>
  );
};

export default Consultoria;
