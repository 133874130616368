import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { NavLink, useLocation } from "react-router-dom";

import ContactForm from "./shared/components/ContactForm";

import "./Foot.css";

import videoSrc from "./media/WaterINTECA.mov";

const Foot = () => {
  let [showPartners, setShowPartners] = useState(true);
  useEffect(() => {
    if (window.innerWidth <= 900) {
      setShowPartners(false);
    }
  }, []);

  //scroll to contact or areas part of the footer
  let loc = useLocation();
  useEffect(() => {
    if (loc.hash === "#contact") {
      const element = document.getElementById("contact");
      element.scrollIntoView();
    }
    if (loc.hash === "#AreasDeNegocio") {
      const element = document.getElementById("areas");
      element.scrollIntoView();
    }
  }, [loc]);
  return (
    <>
      <div className="row m-5 text-center" id="areas">
        <div className="h2 my-5">ÁREAS DE NEGOCIO</div>
        <div className="col my-md-5">
          <div className="my-3">
            <NavLink to="/servicios/financiero">
              <img
                className="mx-auto"
                src={require("./media/dollar.png")}
                alt="INTECA Sector Financiero"
              />
            </NavLink>
          </div>
          <div className="h3 pt-4">SECTOR FINANCIERO</div>
          <div className="p p-md-4">
            Diseñamos e implementamos soluciones digitales enfocados en el UX
          </div>
        </div>
        <div className="col my-md-5">
          <div className="my-3">
            <NavLink to="/servicios/itvas">
              <img
                className="mx-auto"
                src={require("./media/at.png")}
                alt="INTECA IT & VAS"
              />
            </NavLink>
          </div>
          <div className="h3 pt-4">IT & VAS</div>
          <div className="p p-md-4">
            Brindamos servicios de conectividad basados en las mejores prácticas
            con soluciones de clase mundial
          </div>
        </div>
        <div className="col my-md-5">
          <div className="my-3">
            <NavLink to="/servicios/consultoria">
              <img
                className="mx-auto"
                src={require("./media/messages.png")}
                alt="INTECA CONSULTORÍA"
              />
            </NavLink>
          </div>
          <div className="h3 pt-4">CONSULTORÍA</div>
          <div className="p p-md-4">
            Somos una empresa líder en servicios de consultoría para la
            industria financiera y de telecomunicaciones
          </div>
        </div>
      </div>
      <div className="py-5" style={{ backgroundColor: "#307AD9" }}>
        <div className="h2 py-3 text-center">PARTNERS</div>
        <div className="row text-center py-md-5">
          <div className="col" />
          <div className="col">
            <Carousel controls={false}>
              <Carousel.Item>
                <div className="height-special-div" style={{ height: "160px" }}>
                  <img
                    className="mx-auto w-100 img-fluid"
                    src={require("./media/OVU.jpg")}
                    alt="INTECA OVU"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="height-special-div" style={{ height: "160px" }}>
                  <img
                    className="mx-auto w-100"
                    src={require("./media/Bankingly.png")}
                    alt="INTECA BANKINGLY"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="height-special-div" style={{ height: "160px" }}>
                  <img
                    className="mx-auto w-100"
                    src={require("./media/Infocorp.jpg")}
                    alt="INTECA INFORCORP"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="col" />
          {showPartners ? (
            <>
              <div className="col">
                <Carousel controls={false}>
                  <Carousel.Item>
                    <div
                      className="height-special-div"
                      style={{ height: "160px" }}
                    >
                      <img
                        className="mx-auto w-100"
                        src={require("./media/Bankingly.png")}
                        alt="INTECA BANKINGLY"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div
                      className="height-special-div"
                      style={{ height: "160px" }}
                    >
                      <img
                        className="mx-auto w-100"
                        src={require("./media/Infocorp.jpg")}
                        alt="INTECA INFORCORP"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div
                      className="height-special-div"
                      style={{ height: "160px" }}
                    >
                      <img
                        className="mx-auto w-100"
                        src={require("./media/OVU.jpg")}
                        alt="INTECA OVU"
                      />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col" />
            </>
          ) : null}
        </div>
      </div>
      <div className="video-container2">
        <video
          src={videoSrc}
          autoPlay={true}
          muted={true}
          loop={true}
          style={{ filter: "opacity(90%)" }}
        />
        <div className="centered row footWidth">
          <div className="col">
            <div className="text-start m-5">
              <h2 className="text-black">Dirección:</h2>
              <p className="px-lg-3 text-white footStyle">
                Torre Empresarial Halcón, Av. Interoceánica #780 y Siena, Of.
                304, Piso 3
                <br />
                EC170157
                <br />
                Cumbaya, Quito DM - Ecuador
              </p>
              <div className="row">
                <div className="col">
                  <h2 className="text-black">Teléfono:</h2>
                </div>
                <div className="col">
                  <h2 className="text-black">Email:</h2>
                </div>
              </div>
              <div className="row">
                <div className="p col px-3 text-white footStyle">+(593) 2 393 - 1803</div>
                <div className="p col px-3 text-white footStyle">info@inteca.biz</div>
              </div>
            </div>
          </div>
          <div className="col" id="contact">
            <div className="w-100 mx-auto">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center p-3 m-1 text-black">©2022. INTECA S.A.</div>
    </>
  );
};

export default Foot;
